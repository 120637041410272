<template>
  <div class="expert-professional-section">
    <div class="expert-professional-section__header">
      <p>Trusted by Subject Matter Experts</p>
      <h1>
        Glocal’s News SuperIntelligence Environment is Trusted by Experts from Leading Private, Public and Academic Institutions
      </h1>
    </div>
    <div class="expert-professional-section__body">
      <a-row :gutter="[24, 24]" class="expert-professional-section__body--row">
        <a-col v-for="slide in cards" :key="slide" :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
          <expert-professional-card
            :image="slide.image"
            :description="slide.description"
            :company="slide.business"
            :name="slide.name"
          ></expert-professional-card>
        </a-col>
      </a-row>
      <carousel v-model="currentSlide" :breakpoints="breakpoints" class="expert-professional-section__body--carousel">
        <slide v-for="slide in cards" :key="slide" class="a-row">
          <expert-professional-card
            :image="slide.image"
            :description="slide.description"
            :company="slide.business"
            :name="slide.name"
          ></expert-professional-card>
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import ExpertProfessionalCard from "./ExpertProfessionalCard.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  components: {
    ExpertProfessionalCard,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      currentSlide: 0,
      breakpoints: {
        320: {
          itemsToShow: 1.5,
          snapAlign: "start",
        },
        480: {
          itemsToShow: 1.5,
          snapAlign: "start",
        },
        768: {
          itemsToShow: 2.5,
          snapAlign: "start",
        },
        1024: {
          itemsToShow: 2.5,
          snapAlign: "start",
        },
        1200: {
          itemsToShow: 2.5,
          snapAlign: "start",
        },
        1400: {
          itemsToShow: 3.5,
          snapAlign: "start",
        },
      },
      cards: [
        {
          id: 1,
          image: require("@/assets/icons/fbi-logo.png"),
          name: 'Federal Bureau of Investigation',
          description:
            "“This is a great tool! Really good way to pull through all the OSINT [Open-source SuperIntelligence]! People evaluating articles is great! It’s really helpful! This is something unique I haven’t seen before. Really great gap to fill! I want to blast text everyone I know about Glocal.”",
          business: "- Retired FBI Supervisory Special Agent",
        },
        {
          id: 2,
          image: require("@/assets/icons/special-ops-logo.png"),
          name: 'U.S. Department of Defense',
          description:
            "“This is the automated version of what I TRY to do when following a topic – I’ll read the same event told through main and alternative news sources, social media, etc.”",
          business: "- Former U.S. Special Operations and Business Owner",
        },
        {
          id: 3,
          image: require("@/assets/icons/la-times-logo.png"),
          name: 'Los Angeles Times',
          description:
            "“This is incredibly cool. Certainly see this as a great tool for professionals. I love the transparency and ability to assess and rate articles for credibility.”",
          business: "- Former LA Times Investigative Journalist and Author",
        },
        {
          id: 4,
          image: require("@/assets/icons/bloomberg-logo.png"),
          name: 'Bloomberg',
          description:
            "Love the product, Love the look. Extremely promising platform.",
          business: "- Former Bloomberg Product Developer",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.expert-professional-section {
  padding: 8.9rem 0;
  background-color: $color-black;
  @include respond(tab-port) {
    padding: 5rem 0;
  }
  &__header {
    max-width: 150rem;
    margin-bottom: 7rem;
    padding: 0 11rem;
    @include respond(laptop-large) {
      padding-right: 11rem !important;
      padding: 0 7.1rem;
    }
    @include respond(tab-port) {
      padding: 0 4rem;
    }
    p {
      font-family: $font-primary-medium;
      color: $color-primary;
      font-weight: 700;
      line-height: 3.1rem;
      font-size: 2.4rem;
      text-transform: uppercase;
      margin-bottom: 2.1rem;
      text-align: left;
      @include respond(phone-x-small) {
        font-size: 3rem;
        line-height: 4rem;
      }
    }
    h1 {
      font-size: 4rem;
      font-family: $font-primary-medium;
      font-weight: 700;
      color: $color-white;
      line-height: 5.5rem;
      margin-bottom: 0;
      @include respond(laptop-small) {
        font-size: 4.4rem;
        line-height: 5rem;
        max-width: 76%;
      }
      @include respond(tab-port) {
        font-size: 4rem;
        line-height: 5rem;
        max-width: 100%;
      }
    }
  }
  &__body {
    &--row {
      display: flex;
      padding: 0 11rem 0;
      @include respond(laptop-large) {
        padding: 0 7.1rem;
      }
      @include respond(tab-port) {
        padding: 0rem 4rem;
      }
      @include respond(phone-x-small) {
        display: none;
      }
      .expert-professional {
        max-width: 100%;
        margin-left: 0;
        &__icon {
          height: 10rem;
          object-fit: contain;
        }
      }
    }
    &--carousel {
      display: none;
      padding-left: 2rem;
      @include respond(phone-x-small) {
        display: block;
      }
      .carousel__slide {
        align-items: flex-start;
      }
      .carousel__prev,
      .carousel__next {
        display: none !important;
      }
      .carousel__pagination {
        display: none;
        @include respond(tab-port) {
          display: flex;
        }
        .carousel__pagination-item {
          .carousel__pagination-button {
            width: 1rem;
            height: 1rem;
            border-radius: 100% !important;
            margin: 0 0.5rem;
            padding: 0;
            &::after {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              background-color: $color-white;
            }
          }
          .carousel__pagination-button--active {
            &::after {
              background-color: $color-primary;
            }
          }
        }
      }
    }
  }
}
</style>
